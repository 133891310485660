import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import reportWebVitals from './reportWebVitals';
import TruckbaseApp from './TruckbaseApp';
import { GoogleMapsProvider } from './providers/GoogleMaps/GoogleMaps.provider';
import './styles/index.scss';
import Cohere from 'cohere-js';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { AuthProvider } from './providers/Auth/Auth.provider';
import { NotificationsProvider } from './providers/Notifications/Notifications.provider';
import { FeatureFlagsProvider } from './providers/FeatureFlags/FeatureFlags.provider';
import { initializeLogRocket } from './utils/logRocketUtils';
import themeOptions from './theme/index';
import ErrorManagerProvider from './providers/ErrorManager/ErrorManager.provider';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import TwBanner from './components/TwBanner/TwBanner';

// Consider having a theme file when theme is defined in the future.
const theme = createTheme(themeOptions);

const cohereApiKey = '';
if (!process.env.REACT_APP_COHERE_API_KEY && cohereApiKey.length > 0) {
  Cohere.init(cohereApiKey);
}

const logRocketApiKey = 'rxmoa8/truckbase-dev';
if (!process.env.REACT_APP_LOGROCKET_API_KEY && logRocketApiKey.length > 0) {
  initializeLogRocket(logRocketApiKey);
}
const LAUNCHDARKLY_SDK_CLIENT =
  process.env.REACT_APP_LAUNCHDARKLY_SDK_CLIENT || '627d24e8f97be615ca96bf39' || '';

/**
 * Render app only after LaunchDarkly finishes initializing
 */
(async (): Promise<void> => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: LAUNCHDARKLY_SDK_CLIENT
  });

  const container = document.getElementById('root');
  const root = createRoot(container!);

  root.render(
    <>
      <React.StrictMode>
        <LDProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <GoogleMapsProvider>
                  <FeatureFlagsProvider>
                    <BrowserRouter>
                      <AuthProvider>
                        <NotificationsProvider>
                          <ErrorManagerProvider>
                            <Provider store={store}>
                              <TwBanner />
                              <TruckbaseApp />
                            </Provider>
                          </ErrorManagerProvider>
                        </NotificationsProvider>
                      </AuthProvider>
                    </BrowserRouter>
                  </FeatureFlagsProvider>
                </GoogleMapsProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </LDProvider>
      </React.StrictMode>
    </>
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
