import React, { createContext, FC, ReactNode, useContext, useState } from 'react';
import useLoadScript from '../../hooks/useLoadScript';

const GOOGLE_MAPS_API_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyAuxABVJRKpXctE_q5fTRHEVwOPWLWImGw' || '';

interface GoogleMapsContextProps {
  loaded: boolean;
}

export const GoogleMapsContext = createContext<GoogleMapsContextProps>({
  loaded: false
});

export const GoogleMapsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  useLoadScript({
    // eslint-disable-next-line max-len
    resourceUrl: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=directions,places`,
    element: 'body',
    async: true,
    id: 'google-maps',
    onLoad: () => {
      setLoaded(true);
    }
  });

  return <GoogleMapsContext.Provider value={{ loaded }}>{children}</GoogleMapsContext.Provider>;
};

const useGoogleMaps = (): GoogleMapsContextProps => useContext(GoogleMapsContext);

export default useGoogleMaps;
